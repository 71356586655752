import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import Axios from 'axios'
import VueAxios from 'vue-axios'

import 'vant/lib/index.css'; // 引入Vant的样式
import '@/assets/css/normalize.css'
import '@/assets/css/common.css'
import pub from '@/assets/js/public'

Vue.use(VueAxios, Axios)
Vue.use(Vant); // 注册Vant组件库
Vue.use(pub)
Vue.config.productionTip = false

new Vue({
  router, // 注入到Vue实例中
  render: h => h(App),
}).$mount('#app')
