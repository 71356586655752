  // router.js
  import Vue from 'vue'
  import VueRouter from 'vue-router'

  Vue.use(VueRouter)

  const routes = [
    { path: '/', component: () => import('../pages/IndexLogin.vue') },
    { path: '/test', component: () => import('../pages/CabinetTest.vue') },
    { path: '/code', component: () => import('../pages/CodeCan.vue') },
    { path: '/bind', component: () => import('../pages/CabinetBind.vue') }
    // 添加更多路由...
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  export default router